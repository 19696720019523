import React, { useState } from "react";
// import React, { useState, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "../../Hacks/Image";
// import IframeResizer from "iframe-resizer-react";
// import AniLink from 'gatsby-plugin-transition-link/AniLink'

// import Img from "gatsby-image";
// import SoftwareSlant from "../../../img/software-slant-v2-1.png";

import { Fade } from "react-awesome-reveal";


export const FooterRescue = ({ hello, title, intro, link }) => {
  const [open, setOpen] = useState(false);
  // const iframeRef = useRef(null);
  // const [messageData, setMessageData] = useState();

  // const onResized = (data) => setMessageData(data);

  // const onMessage = (data) => {
  //   setMessageData(data);
  //   iframeRef.current.sendMessage("Hello back from the parent page");
  // };

  const SoftwareSlant = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "software-slant-v2-1.png" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
  `);

  return (
    <aside className="rescue bg-primary">
      <div className="rescue_image d-none d-md-block">
        <Fade triggerOnce="true">
          <Image
            image={SoftwareSlant.file}
            className="img-fluid w-100"
            alt=""
          />
        </Fade>
      </div>
      <div className="rescue_inner py-md-5">
        {!open && (
          <div className="rescue_inner_message">
            <Fade>
              <h2>
                <span className="text-secondary">{hello}</span>{" "}
                <span className="d-block d-xl-inline">{title}</span>
              </h2>
            </Fade>
            <Fade>
              <p className="lead">{intro}</p>
            </Fade>
            <Fade>
              <span
                tabIndex="0"
                role="button"
                direction="right"
                bg="#1F1A3C"
                onClick={() => setOpen(!open)}
                onKeyDown={() => setOpen(!open)}
                className="btn btn-secondary"
              >
                {link}
              </span>
            </Fade>
          </div>
        )}
        {open && (
          <div className="py-5">
            <iframe
              // forwardRef={iframeRef}
              heightCalculationMethod="lowestElement"
              inPageLinks
              // onMessage={onMessage}
              // onResized={onResized}
              title={`rescue contact`}
              src="https://demo.databowl.com/site/form.html"
              style={{
                width: "1px",
                minWidth: "100%",
                height: "100vh",
                maxHeight: "950px",
                border: 0,
                transition: "0.3s ease all",
                overflow: "hidden",
              }}
            />
            {/* <iframe
              // forwardRef={iframeRef}
              // heightCalculationMethod="max"
              // inPageLinks
              // log
              // autoResize={true}
              // resizeFrom={("parent", "child")}
              // onMessage={onMessage}
              // onResized={onResized}
              frameBorder="0"
              className=""
              src="https://demo.databowl.com/site/form.html"
              title={title}
              style={{
                width: "1px",
                minWidth: "100%",
                minHeight: "100vh",
                maxHeight: "700px",
                border: 0,
                transition: "0.3s ease all",
              }}
            /> */}
          </div>
        )}
      </div>
    </aside>
  );
};

export default FooterRescue;
